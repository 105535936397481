.financial-investment-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-color: #141414;
}
.financial-investment-header {
  width: 100%;
  display: flex;
  max-width: 1400px;
  align-items: center;
  padding-top: 32px;
  padding-left: 48px;
  padding-right: 48px;
  padding-bottom: 32px;
  justify-content: space-between;
}
.financial-investment-container01 {
  flex: 0 0 auto;
  width: 287px;
  height: 47px;
  display: flex;
  align-items: flex-start;
}
.financial-investment-menu {
  flex: 0 0 auto;
  width: 282px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.financial-investment-logo {
  flex: 0 0 auto;
  width: 100%;
  height: 90px;
  display: flex;
  align-items: flex-start;
}
.financial-investment-navlink {
  display: contents;
}
.financial-investment-image {
  height: 3.2rem;
  align-self: center;
  margin-left: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.financial-investment-summary {
  flex: 0 0 auto;
  width: 100%;
  height: 60px;
  display: flex;
  align-self: flex-start;
  align-items: center;
  padding-left: var(--dl-space-space-fiveunits);
  justify-content: flex-start;
}
.financial-investment-navlink1 {
  color: rgb(224, 224, 224);
  font-size: 20px;
  font-style: normal;
  margin-top: var(--dl-space-space-fourunits);
  font-family: Montserrat;
  font-weight: 300;
  margin-bottom: var(--dl-space-space-fourunits);
}
.financial-investment-market {
  flex: 0 0 auto;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-fiveunits);
}
.financial-investment-navlink2 {
  color: rgb(224, 224, 224);
  font-size: 20px;
  font-style: normal;
  margin-top: var(--dl-space-space-fourunits);
  font-family: Montserrat;
  font-weight: 300;
  margin-bottom: var(--dl-space-space-fourunits);
  text-decoration: none;
}
.financial-investment-solution {
  flex: 0 0 auto;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-fiveunits);
}
.financial-investment-navlink3 {
  color: rgb(224, 224, 224);
  font-size: 20px;
  font-style: normal;
  margin-top: var(--dl-space-space-fourunits);
  font-family: Montserrat;
  font-weight: 300;
  margin-bottom: var(--dl-space-space-fourunits);
  text-decoration: none;
}
.financial-investment-cma {
  flex: 0 0 auto;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-fiveunits);
}
.financial-investment-navlink4 {
  color: rgb(224, 224, 224);
  font-size: 20px;
  font-style: normal;
  margin-top: var(--dl-space-space-fourunits);
  font-family: Montserrat;
  font-weight: 300;
  margin-bottom: var(--dl-space-space-fourunits);
  text-decoration: none;
}
.financial-investment-financial {
  flex: 0 0 auto;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-fiveunits);
}
.financial-investment-text {
  color: rgb(224, 224, 224);
  font-size: 20px;
  font-style: normal;
  margin-top: var(--dl-space-space-fourunits);
  font-family: Montserrat;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-fourunits);
}
.financial-investment-timeline {
  flex: 0 0 auto;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-fiveunits);
}
.financial-investment-navlink5 {
  color: rgb(224, 224, 224);
  font-size: 20px;
  font-style: normal;
  margin-top: var(--dl-space-space-fourunits);
  font-family: Montserrat;
  font-weight: 300;
  margin-bottom: var(--dl-space-space-fourunits);
  text-decoration: none;
}
.financial-investment-team {
  flex: 0 0 auto;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-fiveunits);
}
.financial-investment-navlink6 {
  color: rgb(224, 224, 224);
  font-size: 20px;
  font-style: normal;
  margin-top: var(--dl-space-space-fourunits);
  font-family: Montserrat;
  font-weight: 300;
  margin-bottom: var(--dl-space-space-fourunits);
  text-decoration: none;
}
.financial-investment-contact-us {
  flex: 0 0 auto;
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-tenunits);
  padding-left: var(--dl-space-space-fiveunits);
}
.financial-investment-link {
  color: #e7e7e7;
  font-size: 16px;
  transition: 0.3s;
  padding-top: 10px;
  border-color: #1a1b1f;
  border-width: 0px;
  padding-left: 20px;
  border-radius: 0px;
  padding-right: 20px;
  padding-bottom: 10px;
  text-decoration: none;
  background-color: #1a1b1f;
  border-top-width: 0px;
}
.financial-investment-link:hover {
  color: var(--dl-color-gray-black);
  border-color: var(--dl-color-gray-900);
  border-radius: 0px;
  background-color: var(--dl-color-gray-900);
}
.financial-investment-container02 {
  flex: 0 0 auto;
  width: 952px;
  height: 692px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: #fff;
}
.financial-investment-container03 {
  flex: 0 0 auto;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: flex-start;
}
.financial-investment-container04 {
  flex: 0 0 auto;
  width: 658px;
  height: 60px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.financial-investment-text01 {
  font-size: 28px;
  align-self: flex-start;
  font-style: normal;
  margin-top: 0px;
  font-family: Montserrat;
  font-weight: 600;
  margin-left: var(--dl-space-space-fourunits);
  margin-right: var(--dl-space-space-fourunits);
}
.financial-investment-container05 {
  flex: 0 0 auto;
  width: 301px;
  height: 60px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: #dcfe63;
}
.financial-investment-text04 {
  font-size: 24px;
  align-self: center;
  font-style: normal;
  font-family: Montserrat;
  font-weight: 700;
}
.financial-investment-container06 {
  width: 100%;
  height: 568px;
  display: flex;
  align-self: center;
  align-items: flex-start;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-fourunits);
  padding-right: var(--dl-space-space-fourunits);
  padding-bottom: var(--dl-space-space-doubleunit);
  justify-content: center;
}
.financial-investment-image1 {
  width: 95%;
  align-self: center;
  object-fit: cover;
}
.financial-investment-container07 {
  width: 100%;
  height: 66px;
  display: flex;
  align-items: flex-start;
  padding-left: var(--dl-space-space-tripleunit);
}
.financial-investment-container08 {
  flex: 0 0 auto;
  width: 76px;
  height: 100%;
  display: flex;
  align-self: center;
  text-align: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.financial-investment-navlink7 {
  color: var(--dl-color-gray-black);
  padding: var(--dl-space-space-halfunit);
  font-style: normal;
  font-family: Montserrat;
  font-weight: 400;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.financial-investment-container09 {
  flex: 0 0 auto;
  width: 137px;
  height: 100%;
  display: flex;
  align-self: center;
  text-align: center;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.financial-investment-text05 {
  color: var(--dl-color-gray-white);
  padding: var(--dl-space-space-unit);
  font-style: normal;
  font-family: Montserrat;
  font-weight: 700;
  background-color: var(--dl-color-gray-black);
}
.financial-investment-container10 {
  flex: 0 0 auto;
  width: 190px;
  height: 100%;
  display: flex;
  align-self: center;
  text-align: center;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.financial-investment-navlink8 {
  font-style: normal;
  font-family: Montserrat;
  font-weight: 400;
  text-decoration: none;
}
.financial-investment-container11 {
  flex: 0 0 auto;
  width: 575px;
  height: 100%;
  display: flex;
  align-self: center;
  text-align: center;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
@media(max-width: 767px) {
  .financial-investment-header {
    padding-left: 32px;
    padding-right: 32px;
  }
}
@media(max-width: 479px) {
  .financial-investment-header {
    padding: var(--dl-space-space-unit);
  }
}
