.home-project-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-color: #141414;
}
.home-project-header {
  width: 100%;
  display: flex;
  max-width: 1400px;
  align-items: center;
  padding-top: 32px;
  padding-left: 48px;
  padding-right: 48px;
  padding-bottom: 32px;
  justify-content: space-between;
}
.home-project-container01 {
  flex: 0 0 auto;
  width: 287px;
  height: 47px;
  display: flex;
  align-items: flex-start;
}
.home-project-menu {
  flex: 0 0 auto;
  width: 282px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-project-logo {
  flex: 0 0 auto;
  width: 100%;
  height: 90px;
  display: flex;
  align-items: flex-start;
}
.home-project-navlink {
  display: contents;
}
.home-project-image {
  height: 3.2rem;
  align-self: center;
  margin-left: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.home-project-summary {
  flex: 0 0 auto;
  width: 100%;
  height: 60px;
  display: flex;
  align-self: flex-start;
  align-items: center;
  padding-left: var(--dl-space-space-fiveunits);
  justify-content: flex-start;
}
.home-project-navlink01 {
  color: rgb(224, 224, 224);
  font-size: 20px;
  font-style: normal;
  margin-top: var(--dl-space-space-fourunits);
  font-family: Montserrat;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-fourunits);
}
.home-project-market {
  flex: 0 0 auto;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-fiveunits);
}
.home-project-navlink02 {
  color: rgb(224, 224, 224);
  font-size: 20px;
  font-style: normal;
  margin-top: var(--dl-space-space-fourunits);
  font-family: Montserrat;
  font-weight: 300;
  margin-bottom: var(--dl-space-space-fourunits);
  text-decoration: none;
}
.home-project-solution {
  flex: 0 0 auto;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-fiveunits);
}
.home-project-navlink03 {
  color: rgb(224, 224, 224);
  font-size: 20px;
  font-style: normal;
  margin-top: var(--dl-space-space-fourunits);
  font-family: Montserrat;
  font-weight: 300;
  margin-bottom: var(--dl-space-space-fourunits);
  text-decoration: none;
}
.home-project-cma {
  flex: 0 0 auto;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-fiveunits);
}
.home-project-navlink04 {
  color: rgb(224, 224, 224);
  font-size: 20px;
  font-style: normal;
  margin-top: var(--dl-space-space-fourunits);
  font-family: Montserrat;
  font-weight: 300;
  margin-bottom: var(--dl-space-space-fourunits);
  text-decoration: none;
}
.home-project-financial {
  flex: 0 0 auto;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-fiveunits);
}
.home-project-navlink05 {
  color: rgb(224, 224, 224);
  font-size: 20px;
  font-style: normal;
  margin-top: var(--dl-space-space-fourunits);
  font-family: Montserrat;
  font-weight: 300;
  margin-bottom: var(--dl-space-space-fourunits);
  text-decoration: none;
}
.home-project-timeline {
  flex: 0 0 auto;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-fiveunits);
}
.home-project-navlink06 {
  color: rgb(224, 224, 224);
  font-size: 20px;
  font-style: normal;
  margin-top: var(--dl-space-space-fourunits);
  font-family: Montserrat;
  font-weight: 300;
  margin-bottom: var(--dl-space-space-fourunits);
  text-decoration: none;
}
.home-project-team {
  flex: 0 0 auto;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-fiveunits);
}
.home-project-navlink07 {
  color: rgb(224, 224, 224);
  font-size: 20px;
  font-style: normal;
  margin-top: var(--dl-space-space-fourunits);
  font-family: Montserrat;
  font-weight: 300;
  margin-bottom: var(--dl-space-space-fourunits);
  text-decoration: none;
}
.home-project-contact-us {
  flex: 0 0 auto;
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-tenunits);
  padding-left: var(--dl-space-space-fiveunits);
}
.home-project-link {
  color: #e7e7e7;
  font-size: 16px;
  transition: 0.3s;
  padding-top: 10px;
  border-color: #1a1b1f;
  border-width: 0px;
  padding-left: 20px;
  border-radius: 0px;
  padding-right: 20px;
  padding-bottom: 10px;
  text-decoration: none;
  background-color: #1a1b1f;
  border-top-width: 0px;
}
.home-project-link:hover {
  color: var(--dl-color-gray-black);
  border-color: var(--dl-color-gray-900);
  border-radius: 0px;
  background-color: var(--dl-color-gray-900);
}
.home-project-container02 {
  flex: 0 0 auto;
  width: 952px;
  height: 692px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: #ffffff;
}
.home-project-container03 {
  flex: 0 0 auto;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: flex-start;
}
.home-project-container04 {
  flex: 0 0 auto;
  width: 658px;
  height: 60px;
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-fourunits);
  flex-direction: column;
  justify-content: center;
}
.home-project-text {
  font-size: 28px;
  align-self: flex-start;
  font-style: normal;
  margin-top: 0px;
  font-family: Montserrat;
  font-weight: 600;
  margin-left: 0px;
  margin-right: 0px;
}
.home-project-container05 {
  flex: 0 0 auto;
  width: 301px;
  height: 60px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: #dcfe63;
}
.home-project-text01 {
  font-size: 24px;
  align-self: center;
  font-style: normal;
  font-family: Montserrat;
  font-weight: 700;
}
.home-project-container06 {
  width: 100%;
  height: 566px;
  display: flex;
  overflow: auto;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-fourunits);
  padding-right: var(--dl-space-space-fourunits);
  padding-bottom: var(--dl-space-space-unit);
}
.home-project-container07 {
  flex: 0 0 auto;
  width: 100%;
  height: 802px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-project-text02 {
  font-style: normal;
  font-family: Montserrat;
  font-weight: 700;
}
.home-project-text03 {
  font-family: Montserrat;
}
.home-project-text04 {
  font-style: normal;
  font-family: Montserrat;
  font-weight: 700;
  padding-top: var(--dl-space-space-doubleunit);
}
.home-project-text05 {
  font-family: Montserrat;
}
.home-project-text06 {
  font-style: normal;
  font-family: Montserrat;
  font-weight: 700;
  padding-top: var(--dl-space-space-doubleunit);
}
.home-project-text07 {
  font-family: Montserrat;
}
.home-project-text10 {
  font-style: normal;
  font-family: Montserrat;
  font-weight: 700;
  padding-top: var(--dl-space-space-doubleunit);
}
.home-project-text11 {
  font-family: Montserrat;
}
.home-project-text14 {
  font-style: normal;
  font-family: Montserrat;
  font-weight: 700;
  padding-top: var(--dl-space-space-doubleunit);
}
.home-project-text15 {
  font-family: Montserrat;
}
.home-project-text18 {
  font-style: normal;
  font-family: "Montserrat";
  font-weight: 700;
  padding-top: var(--dl-space-space-doubleunit);
}
.home-project-text19 {
  font-family: "Montserrat";
}
.home-project-text22 {
  font-style: normal;
  font-family: Montserrat;
  font-weight: 700;
  padding-top: var(--dl-space-space-doubleunit);
}
.home-project-text23 {
  font-family: Montserrat;
}
.home-project-text26 {
  font-style: normal;
  font-family: Montserrat;
  font-weight: 700;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
}
.home-project-text27 {
  font-family: Montserrat;
}
.home-project-text30 {
  font-style: normal;
  font-family: Montserrat;
  font-weight: 700;
  padding-top: var(--dl-space-space-doubleunit);
}
.home-project-text31 {
  font-family: "Montserrat";
}
.home-project-container08 {
  width: 100%;
  height: 66px;
  display: flex;
  align-items: flex-start;
  padding-left: var(--dl-space-space-tripleunit);
}
.home-project-container09 {
  flex: 0 0 auto;
  width: 70px;
  height: 100%;
  display: flex;
  align-self: center;
  text-align: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-project-navlink08 {
  color: var(--dl-color-gray-black);
  font-style: normal;
  font-family: "Montserrat";
  font-weight: 400;
  padding-top: 0px;
  padding-left: var(--dl-space-space-halfunit);
  padding-right: var(--dl-space-space-halfunit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.home-project-container10 {
  flex: 0 0 auto;
  width: 93px;
  height: 100%;
  display: flex;
  align-self: center;
  text-align: center;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-project-navlink09 {
  color: rgb(255, 255, 255);
  padding: var(--dl-space-space-unit);
  font-style: normal;
  font-family: "Montserrat";
  font-weight: 700;
  text-decoration: none;
  background-color: rgb(0, 0, 0);
}
.home-project-container11 {
  flex: 0 0 auto;
  width: 190px;
  height: 100%;
  display: flex;
  align-self: center;
  text-align: center;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-project-navlink10 {
  font-style: normal;
  font-family: "Montserrat";
  font-weight: 400;
  text-decoration: none;
}
.home-project-container12 {
  flex: 0 0 auto;
  width: 104px;
  height: 100%;
  display: flex;
  align-self: center;
  text-align: center;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-project-navlink11 {
  font-style: normal;
  font-family: "Montserrat";
  font-weight: 400;
  text-decoration: none;
}
.home-project-container13 {
  flex: 0 0 auto;
  width: 50%;
  height: 100%;
  display: flex;
  align-self: center;
  text-align: center;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-project-navlink12 {
  color: var(--dl-color-gray-black);
  padding: var(--dl-space-space-halfunit);
  font-style: normal;
  font-family: "Montserrat";
  font-weight: 400;
}
@media(max-width: 767px) {
  .home-project-header {
    padding-left: 32px;
    padding-right: 32px;
  }
}
@media(max-width: 479px) {
  .home-project-header {
    padding: var(--dl-space-space-unit);
  }
}
