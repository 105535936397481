.blank-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-color: #141414;
}
.blank-header {
  width: 100%;
  display: flex;
  max-width: 1400px;
  align-items: center;
  padding-top: 32px;
  padding-left: 48px;
  padding-right: 48px;
  padding-bottom: 32px;
  justify-content: space-between;
}
.blank-container1 {
  flex: 0 0 auto;
  width: 287px;
  height: 47px;
  display: flex;
  align-items: flex-start;
}
.blank-menu {
  flex: 0 0 auto;
  width: 282px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.blank-logo {
  flex: 0 0 auto;
  width: 100%;
  height: 90px;
  display: flex;
  align-items: flex-start;
}
.blank-image {
  height: 3.2rem;
  align-self: center;
  margin-left: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.blank-summary {
  flex: 0 0 auto;
  width: 100%;
  height: 60px;
  display: flex;
  align-self: flex-start;
  align-items: center;
  padding-left: var(--dl-space-space-fiveunits);
  justify-content: flex-start;
}
.blank-text {
  color: rgb(224, 224, 224);
  font-size: 20px;
  font-style: normal;
  margin-top: var(--dl-space-space-fourunits);
  font-family: Montserrat;
  font-weight: 300;
  margin-bottom: var(--dl-space-space-fourunits);
  text-decoration: none;
}
.blank-market {
  flex: 0 0 auto;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-fiveunits);
}
.blank-link {
  color: rgb(224, 224, 224);
  font-size: 20px;
  font-style: normal;
  margin-top: var(--dl-space-space-fourunits);
  font-family: Montserrat;
  font-weight: 300;
  margin-bottom: var(--dl-space-space-fourunits);
}
.blank-solution {
  flex: 0 0 auto;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-fiveunits);
}
.blank-link1 {
  color: rgb(224, 224, 224);
  font-size: 20px;
  font-style: normal;
  margin-top: var(--dl-space-space-fourunits);
  font-family: Montserrat;
  font-weight: 300;
  margin-bottom: var(--dl-space-space-fourunits);
}
.blank-cma {
  flex: 0 0 auto;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-fiveunits);
}
.blank-link2 {
  color: rgb(224, 224, 224);
  font-size: 20px;
  font-style: normal;
  margin-top: var(--dl-space-space-fourunits);
  font-family: Montserrat;
  font-weight: 300;
  margin-bottom: var(--dl-space-space-fourunits);
}
.blank-financial {
  flex: 0 0 auto;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-fiveunits);
}
.blank-link3 {
  color: rgb(224, 224, 224);
  font-size: 20px;
  font-style: normal;
  margin-top: var(--dl-space-space-fourunits);
  font-family: Montserrat;
  font-weight: 300;
  margin-bottom: var(--dl-space-space-fourunits);
}
.blank-timeline {
  flex: 0 0 auto;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-fiveunits);
}
.blank-link4 {
  color: rgb(224, 224, 224);
  font-size: 20px;
  font-style: normal;
  margin-top: var(--dl-space-space-fourunits);
  font-family: Montserrat;
  font-weight: 300;
  margin-bottom: var(--dl-space-space-fourunits);
}
.blank-team {
  flex: 0 0 auto;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-fiveunits);
}
.blank-navlink {
  color: rgb(224, 224, 224);
  font-size: 20px;
  font-style: normal;
  margin-top: var(--dl-space-space-fourunits);
  font-family: Montserrat;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-fourunits);
  text-decoration: none;
}
.blank-contact-us {
  flex: 0 0 auto;
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-tenunits);
  padding-left: var(--dl-space-space-fiveunits);
}
.blank-button {
  color: #e7e7e7;
  font-size: 16px;
  transition: 0.3s;
  padding-top: 10px;
  border-color: #1a1b1f;
  border-width: 0px;
  padding-left: 20px;
  border-radius: 0px;
  padding-right: 20px;
  padding-bottom: 10px;
  background-color: #1a1b1f;
  border-top-width: 0px;
}
.blank-button:hover {
  color: var(--dl-color-gray-black);
  border-color: var(--dl-color-gray-900);
  border-radius: 0px;
  background-color: var(--dl-color-gray-900);
}
.blank-container2 {
  flex: 0 0 auto;
  width: 952px;
  height: 692px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: #e3e3e3;
}
.blank-container3 {
  flex: 0 0 auto;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: flex-start;
}
.blank-container4 {
  flex: 0 0 auto;
  width: 658px;
  height: 60px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.blank-container5 {
  flex: 0 0 auto;
  width: 301px;
  height: 60px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: #dcfe63;
}
.blank-text1 {
  font-size: 24px;
  align-self: center;
  font-style: normal;
  font-family: Montserrat;
  font-weight: 500;
}
.blank-container6 {
  width: 100%;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  height: 630px;
  display: flex;
  align-items: flex-start;
}
@media(max-width: 767px) {
  .blank-header {
    padding-left: 32px;
    padding-right: 32px;
  }
}
@media(max-width: 479px) {
  .blank-header {
    padding: var(--dl-space-space-unit);
  }
}
