.home-mvp-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-color: #141414;
}
.home-mvp-header {
  width: 100%;
  display: flex;
  max-width: 1400px;
  align-items: center;
  padding-top: 32px;
  padding-left: 48px;
  padding-right: 48px;
  padding-bottom: 32px;
  justify-content: space-between;
}
.home-mvp-container01 {
  flex: 0 0 auto;
  width: 287px;
  height: 47px;
  display: flex;
  align-items: flex-start;
}
.home-mvp-menu {
  flex: 0 0 auto;
  width: 282px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-mvp-logo {
  flex: 0 0 auto;
  width: 100%;
  height: 90px;
  display: flex;
  align-items: flex-start;
}
.home-mvp-navlink {
  display: contents;
}
.home-mvp-image {
  height: 3.2rem;
  align-self: center;
  margin-left: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.home-mvp-summary {
  flex: 0 0 auto;
  width: 100%;
  height: 60px;
  display: flex;
  align-self: flex-start;
  align-items: center;
  padding-left: var(--dl-space-space-fiveunits);
  justify-content: flex-start;
}
.home-mvp-navlink01 {
  color: rgb(224, 224, 224);
  font-size: 20px;
  font-style: normal;
  margin-top: var(--dl-space-space-fourunits);
  font-family: Montserrat;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-fourunits);
}
.home-mvp-market {
  flex: 0 0 auto;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-fiveunits);
}
.home-mvp-navlink02 {
  color: rgb(224, 224, 224);
  font-size: 20px;
  font-style: normal;
  margin-top: var(--dl-space-space-fourunits);
  font-family: Montserrat;
  font-weight: 300;
  margin-bottom: var(--dl-space-space-fourunits);
  text-decoration: none;
}
.home-mvp-solution {
  flex: 0 0 auto;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-fiveunits);
}
.home-mvp-navlink03 {
  color: rgb(224, 224, 224);
  font-size: 20px;
  font-style: normal;
  margin-top: var(--dl-space-space-fourunits);
  font-family: Montserrat;
  font-weight: 300;
  margin-bottom: var(--dl-space-space-fourunits);
  text-decoration: none;
}
.home-mvp-cma {
  flex: 0 0 auto;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-fiveunits);
}
.home-mvp-navlink04 {
  color: rgb(224, 224, 224);
  font-size: 20px;
  font-style: normal;
  margin-top: var(--dl-space-space-fourunits);
  font-family: Montserrat;
  font-weight: 300;
  margin-bottom: var(--dl-space-space-fourunits);
  text-decoration: none;
}
.home-mvp-financial {
  flex: 0 0 auto;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-fiveunits);
}
.home-mvp-navlink05 {
  color: rgb(224, 224, 224);
  font-size: 20px;
  font-style: normal;
  margin-top: var(--dl-space-space-fourunits);
  font-family: Montserrat;
  font-weight: 300;
  margin-bottom: var(--dl-space-space-fourunits);
  text-decoration: none;
}
.home-mvp-timeline {
  flex: 0 0 auto;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-fiveunits);
}
.home-mvp-navlink06 {
  color: rgb(224, 224, 224);
  font-size: 20px;
  font-style: normal;
  margin-top: var(--dl-space-space-fourunits);
  font-family: Montserrat;
  font-weight: 300;
  margin-bottom: var(--dl-space-space-fourunits);
  text-decoration: none;
}
.home-mvp-team {
  flex: 0 0 auto;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-fiveunits);
}
.home-mvp-navlink07 {
  color: rgb(224, 224, 224);
  font-size: 20px;
  font-style: normal;
  margin-top: var(--dl-space-space-fourunits);
  font-family: Montserrat;
  font-weight: 300;
  margin-bottom: var(--dl-space-space-fourunits);
  text-decoration: none;
}
.home-mvp-contact-us {
  flex: 0 0 auto;
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-tenunits);
  padding-left: var(--dl-space-space-fiveunits);
}
.home-mvp-link {
  color: #e7e7e7;
  font-size: 16px;
  transition: 0.3s;
  padding-top: 10px;
  border-color: #1a1b1f;
  border-width: 0px;
  padding-left: 20px;
  border-radius: 0px;
  padding-right: 20px;
  padding-bottom: 10px;
  text-decoration: none;
  background-color: #1a1b1f;
  border-top-width: 0px;
}
.home-mvp-link:hover {
  color: var(--dl-color-gray-black);
  border-color: var(--dl-color-gray-900);
  border-radius: 0px;
  background-color: var(--dl-color-gray-900);
}
.home-mvp-container02 {
  flex: 0 0 auto;
  width: 952px;
  height: 692px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: #ffffff;
}
.home-mvp-container03 {
  flex: 0 0 auto;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: flex-start;
}
.home-mvp-container04 {
  flex: 0 0 auto;
  width: 658px;
  height: 60px;
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-fourunits);
  flex-direction: column;
  justify-content: center;
}
.home-mvp-text {
  font-size: 28px;
  align-self: flex-start;
  font-style: normal;
  margin-top: 0px;
  font-family: "Montserrat";
  font-weight: 600;
  margin-left: 0px;
  margin-right: 0px;
}
.home-mvp-container05 {
  flex: 0 0 auto;
  width: 301px;
  height: 60px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: #dcfe63;
}
.home-mvp-text1 {
  font-size: 24px;
  align-self: center;
  font-style: normal;
  font-family: Montserrat;
  font-weight: 700;
}
.home-mvp-container06 {
  width: 100%;
  height: 566px;
  display: flex;
  overflow: auto;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-fourunits);
  padding-right: var(--dl-space-space-fourunits);
  padding-bottom: var(--dl-space-space-unit);
}
.home-mvp-container07 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-mvp-image1 {
  width: 100%;
  height: 429px;
  align-self: center;
  object-fit: cover;
}
.home-mvp-container08 {
  width: 100%;
  height: 66px;
  display: flex;
  align-items: flex-start;
  padding-left: var(--dl-space-space-tripleunit);
}
.home-mvp-container09 {
  flex: 0 0 auto;
  width: 76px;
  height: 100%;
  display: flex;
  align-self: center;
  text-align: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-mvp-navlink08 {
  color: #000000;
  font-style: normal;
  font-family: Montserrat;
  font-weight: 400;
}
.home-mvp-container10 {
  flex: 0 0 auto;
  width: 93px;
  height: 100%;
  display: flex;
  align-self: center;
  text-align: center;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-mvp-navlink09 {
  font-style: normal;
  font-family: Montserrat;
  font-weight: 400;
  text-decoration: none;
}
.home-mvp-container11 {
  flex: 0 0 auto;
  width: 190px;
  height: 100%;
  display: flex;
  align-self: center;
  text-align: center;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-mvp-navlink10 {
  font-style: normal;
  font-family: Montserrat;
  font-weight: 400;
  text-decoration: none;
}
.home-mvp-container12 {
  flex: 0 0 auto;
  width: 104px;
  height: 100%;
  display: flex;
  align-self: center;
  text-align: center;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-mvp-navlink11 {
  font-style: normal;
  font-family: "Montserrat";
  font-weight: 400;
  text-decoration: none;
}
.home-mvp-container13 {
  flex: 0 0 auto;
  width: 50%;
  height: 100%;
  display: flex;
  align-self: center;
  text-align: center;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-mvp-navlink12 {
  color: #ffffff;
  padding: var(--dl-space-space-unit);
  font-style: normal;
  font-family: "Montserrat";
  font-weight: bold;
  background-color: var(--dl-color-gray-black);
}
@media(max-width: 767px) {
  .home-mvp-header {
    padding-left: 32px;
    padding-right: 32px;
  }
}
@media(max-width: 479px) {
  .home-mvp-header {
    padding: var(--dl-space-space-unit);
  }
}
