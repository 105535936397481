.home-summary-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-color: #141414;
}
.home-summary-header {
  width: 100%;
  display: flex;
  max-width: 1400px;
  align-items: center;
  padding-top: 32px;
  padding-left: 48px;
  padding-right: 48px;
  padding-bottom: 32px;
  justify-content: space-between;
}
.home-summary-container01 {
  flex: 0 0 auto;
  width: 287px;
  height: 47px;
  display: flex;
  align-items: flex-start;
}
.home-summary-menu {
  flex: 0 0 auto;
  width: 282px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-summary-logo {
  flex: 0 0 auto;
  width: 100%;
  height: 90px;
  display: flex;
  align-items: flex-start;
}
.home-summary-navlink {
  display: contents;
}
.home-summary-image {
  height: 3.2rem;
  align-self: center;
  margin-left: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.home-summary-summary {
  flex: 0 0 auto;
  width: 100%;
  height: 60px;
  display: flex;
  align-self: flex-start;
  align-items: center;
  padding-left: var(--dl-space-space-fiveunits);
  justify-content: flex-start;
}
.home-summary-navlink01 {
  color: rgb(224, 224, 224);
  font-size: 20px;
  font-style: normal;
  margin-top: var(--dl-space-space-fourunits);
  font-family: Montserrat;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-fourunits);
}
.home-summary-market {
  flex: 0 0 auto;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-fiveunits);
}
.home-summary-navlink02 {
  color: rgb(224, 224, 224);
  font-size: 20px;
  font-style: normal;
  margin-top: var(--dl-space-space-fourunits);
  font-family: Montserrat;
  font-weight: 300;
  margin-bottom: var(--dl-space-space-fourunits);
  text-decoration: none;
}
.home-summary-solution {
  flex: 0 0 auto;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-fiveunits);
}
.home-summary-navlink03 {
  color: rgb(224, 224, 224);
  font-size: 20px;
  font-style: normal;
  margin-top: var(--dl-space-space-fourunits);
  font-family: Montserrat;
  font-weight: 300;
  margin-bottom: var(--dl-space-space-fourunits);
  text-decoration: none;
}
.home-summary-cma {
  flex: 0 0 auto;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-fiveunits);
}
.home-summary-navlink04 {
  color: rgb(224, 224, 224);
  font-size: 20px;
  font-style: normal;
  margin-top: var(--dl-space-space-fourunits);
  font-family: Montserrat;
  font-weight: 300;
  margin-bottom: var(--dl-space-space-fourunits);
  text-decoration: none;
}
.home-summary-financial {
  flex: 0 0 auto;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-fiveunits);
}
.home-summary-navlink05 {
  color: rgb(224, 224, 224);
  font-size: 20px;
  font-style: normal;
  margin-top: var(--dl-space-space-fourunits);
  font-family: Montserrat;
  font-weight: 300;
  margin-bottom: var(--dl-space-space-fourunits);
  text-decoration: none;
}
.home-summary-timeline {
  flex: 0 0 auto;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-fiveunits);
}
.home-summary-navlink06 {
  color: rgb(224, 224, 224);
  font-size: 20px;
  font-style: normal;
  margin-top: var(--dl-space-space-fourunits);
  font-family: Montserrat;
  font-weight: 300;
  margin-bottom: var(--dl-space-space-fourunits);
  text-decoration: none;
}
.home-summary-team {
  flex: 0 0 auto;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-fiveunits);
}
.home-summary-navlink07 {
  color: rgb(224, 224, 224);
  font-size: 20px;
  font-style: normal;
  margin-top: var(--dl-space-space-fourunits);
  font-family: Montserrat;
  font-weight: 300;
  margin-bottom: var(--dl-space-space-fourunits);
  text-decoration: none;
}
.home-summary-contact-us {
  flex: 0 0 auto;
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-tenunits);
  padding-left: var(--dl-space-space-fiveunits);
}
.home-summary-link {
  color: #e7e7e7;
  font-size: 16px;
  transition: 0.3s;
  padding-top: 10px;
  border-color: #1a1b1f;
  border-width: 0px;
  padding-left: 20px;
  border-radius: 0px;
  padding-right: 20px;
  padding-bottom: 10px;
  text-decoration: none;
  background-color: #1a1b1f;
  border-top-width: 0px;
}
.home-summary-link:hover {
  color: var(--dl-color-gray-black);
  border-color: var(--dl-color-gray-900);
  border-radius: 0px;
  background-color: var(--dl-color-gray-900);
}
.home-summary-container02 {
  flex: 0 0 auto;
  width: 952px;
  height: 692px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: #fff;
}
.home-summary-container03 {
  flex: 0 0 auto;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: flex-start;
}
.home-summary-container04 {
  flex: 0 0 auto;
  width: 658px;
  height: 60px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-summary-text {
  font-size: 28px;
  align-self: flex-start;
  font-style: normal;
  margin-top: 0px;
  font-family: Montserrat;
  font-weight: 600;
  margin-left: var(--dl-space-space-fourunits);
  margin-right: var(--dl-space-space-fourunits);
}
.home-summary-container05 {
  flex: 0 0 auto;
  width: 301px;
  height: 60px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: #dcfe63;
}
.home-summary-text03 {
  font-size: 24px;
  align-self: center;
  font-style: normal;
  font-family: Montserrat;
  font-weight: 700;
}
.home-summary-container06 {
  width: 100%;
  height: 566px;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-fourunits);
  padding-right: var(--dl-space-space-fourunits);
  padding-bottom: var(--dl-space-space-fiveunits);
}
.home-summary-text04 {
  font-size: 17px;
  text-align: justify;
}
.home-summary-text05 {
  font-size: 18px;
  font-style: normal;
  font-family: "Montserrat";
  font-weight: 400;
}
.home-summary-text06 {
  font-size: 18px;
  font-style: normal;
  font-family: "Montserrat";
  font-weight: 700;
}
.home-summary-text07 {
  font-size: 18px;
  font-style: normal;
  font-family: "Montserrat";
  font-weight: 400;
}
.home-summary-text08 {
  font-size: 18px;
  font-style: normal;
  font-family: "Montserrat";
  font-weight: 400;
}
.home-summary-text09 {
  font-size: 18px;
  font-style: normal;
  font-family: "Montserrat";
  font-weight: 400;
}
.home-summary-text10 {
  font-size: 18px;
  font-style: normal;
  font-family: "Montserrat";
  font-weight: 400;
}
.home-summary-text11 {
  font-size: 18px;
  font-style: normal;
  font-family: "Montserrat";
  font-weight: 400;
}
.home-summary-text12 {
  font-size: 18px;
  font-style: normal;
  font-family: "Montserrat";
  font-weight: 400;
}
.home-summary-text13 {
  font-size: 18px;
  font-style: normal;
  font-family: "Montserrat";
  font-weight: 400;
}
.home-summary-container07 {
  width: 100%;
  height: 66px;
  display: flex;
  align-items: flex-start;
  padding-left: var(--dl-space-space-tripleunit);
}
.home-summary-container08 {
  flex: 0 0 auto;
  width: 76px;
  height: 100%;
  display: flex;
  align-self: center;
  text-align: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-summary-navlink08 {
  color: var(--dl-color-gray-white);
  padding: var(--dl-space-space-halfunit);
  font-style: normal;
  font-family: Montserrat;
  font-weight: 700;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-unit);
  background-color: var(--dl-color-gray-black);
}
.home-summary-container09 {
  flex: 0 0 auto;
  width: 93px;
  height: 100%;
  display: flex;
  align-self: center;
  text-align: center;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-summary-navlink09 {
  font-style: normal;
  font-family: Montserrat;
  font-weight: 400;
  text-decoration: none;
}
.home-summary-container10 {
  flex: 0 0 auto;
  width: 190px;
  height: 100%;
  display: flex;
  align-self: center;
  text-align: center;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-summary-navlink10 {
  font-style: normal;
  font-family: Montserrat;
  font-weight: 400;
  text-decoration: none;
}
.home-summary-container11 {
  flex: 0 0 auto;
  width: 104px;
  height: 100%;
  display: flex;
  align-self: center;
  text-align: center;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-summary-navlink11 {
  font-style: normal;
  font-family: "Montserrat";
  font-weight: 400;
  text-decoration: none;
}
.home-summary-container12 {
  flex: 0 0 auto;
  width: 50%;
  height: 100%;
  display: flex;
  align-self: center;
  text-align: center;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-summary-navlink12 {
  color: var(--dl-color-gray-black);
  padding: var(--dl-space-space-halfunit);
  font-style: normal;
  font-family: "Montserrat";
  font-weight: 400;
}
@media(max-width: 767px) {
  .home-summary-header {
    padding-left: 32px;
    padding-right: 32px;
  }
}
@media(max-width: 479px) {
  .home-summary-header {
    padding: var(--dl-space-space-unit);
  }
}
